/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import LogoCartaAoAutor from '../assets/images/lp2/logo-carta-ao-autor.svg'
import HeaderImg from '../assets/images/lp2/autor.png'

import { 
  header,
  logoImg, 
  titleHeader,
  contentHeader,
  DDesktop,
  DMobile
}  from '../assets/styles/LP.styles'

const ContentSection = () => {
  return (
    <section css={header}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div css={logoImg}>
              <img src={LogoCartaAoAutor} alt="Carta ao Autor" title="Carta ao Autor" />
              <div css={DDesktop} style={{ marginLeft: 'auto' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}> 
                  <p >cadastrado?</p>
                  <a  href="https://appcarta.redacaosolidaria.org">ACESSE</a>
                </div>
              </div>
              <div css={DMobile}>
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column' }}> 
                  <p style={{ marginRight: 0 }}>cadastrado?</p>
                  <a href="https://appcarta.redacaosolidaria.org/auth/register/" style={{ marginBottom: 0 }}>ACESSE</a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <h1 css={titleHeader}>Troque uma ideia com o palestrante do Fronteiras.</h1>
            {/* <div><img css={DMobile} src={HeaderImg}  /></div> */}
            <p css={contentHeader}>Faça uma carta com um questionamento sobre as ideias existentes na obra “O Despertar de tudo”. <span>O autor da melhor carta fará sua pergunta diretamente à david wengrow no próximo evento em Porto Alegre.</span></p>
            <a href="https://appcarta.redacaosolidaria.org/auth/register/">INSCREVA-SE AGORA</a>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div><img  src={HeaderImg}  /></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContentSection;
