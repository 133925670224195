/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import ImgBilhete from '../assets/images/lp2/bilhete.svg'
import ImgEnvelope from '../assets/images/lp2/envelope.svg'
import ImgCelular from '../assets/images/lp2/celular.svg'
import ImgDuvida from '../assets/images/lp2/duvida.svg'
import ImgMicrofone from '../assets/images/lp2/microfone.svg'
import BookRedacao from '../assets/images/lp2/redacao-solidaria.png'

import { 
  ppaContent,
  titleContent,
  subtitleContent,
  boxContent,
  DDesktop,
  DMobile,
  redacaoSolidaria,
  itemContent,
}  from '../assets/styles/LP.styles'

const ContentSection = () => {
  return (
    <Fragment>
      <section css={ppaContent}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <h2 css={titleContent}>O QUE VOCÊ DEVE SABER PARA PARTICIPAR</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <div css={itemContent}>
                <img src={ImgBilhete} />
                <p>A melhor carta garante PASSE LIVRE para assistir à palestra de <span>David Wengrow</span> em Porto Alegre.</p>
              </div>
            </Col>
            <Col lg={5} md={5} sm={12}>
              <div css={itemContent}>
                <img src={ImgEnvelope} />
                <p>As <span>10 melhores</span> cartas serão publicadas no <i>site</i> da plataforma Redação Solidária.</p>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
          </Row>
          <Row>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <div css={itemContent}>
                <img src={ImgCelular} />
                <p>O texto deve ser <span>escrito a mão</span>, fotografado e, só então, deve subir para a plataforma do Redação Solidária.</p>
              </div>
            </Col>
            <Col lg={5} md={5} sm={12}>
              <div css={itemContent}>
                <img src={ImgDuvida} />
                <p>A carta que você vai escrever deve conter um questionamento ao autor com o <span>mínimo de 10</span> e o <span>máximo de 30</span> linhas.</p>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
          </Row>
          <Row>
            <Col lg={4} md={4} sm={12} />
            <Col lg={4} md={4} sm={12}>
              <div css={itemContent}>
                <img src={ImgMicrofone} />
                <p>O resultado do concurso Carta ao Autor será divulgado no dia 3 de outubro. O vencedor vai assistir à palestra presencialmente e <span>fará sua pergunta diretamente ao palestrante David Wengrow.</span></p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} />
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="text-center" style={{ marginTop: 72, marginBottom: 90 }}>
               <a href="https://appcarta.redacaosolidaria.org/auth/register/">PARTICIPE</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={redacaoSolidaria}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <img src={BookRedacao}  />
              <h2>JÁ CONHECE O <span>REDAÇÃO SOLIDÁRIA?</span></h2>
              <p>A plataforma do Redação Solidária disponibiliza avaliação e revisão, feita por professores qualificados, de forma gratuita.</p>
              <a href="https://redacaosolidaria.org/" target='_blank'>conheça o projeto</a>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ContentSection;
